'use client';

import cx from 'classnames';

import { Event, trackEvent } from '~/ui/components/analytics';
import { Button } from '~/v1/components/button/button';
import { TEST_ID } from '~/v1/constants/testId';

import { type CallToActionProps } from './callToAction.interface';
import styles from './callToAction.module.scss';
import { ButtonMode } from '../button/button.interface';

export const CallToAction: React.FC<CallToActionProps> = ({
  text,
  buttonLabel,
  buttonType,
  className,
  href,
  ...props
}) => {
  const buttonClasses = cx(styles.button, className);

  return (
    <div data-test-id={TEST_ID.CALL_TO_ACTION} className={buttonClasses}>
      {text && <p className={cx('modulesTitleTypography', styles.text)}>{text}</p>}
      {buttonLabel && (
        <Button
          href={href}
          type={buttonType}
          onClick={() =>
            trackEvent(Event.CALL_TO_ACTION_CLICK, {
              callToActionCTA: buttonLabel,
              callToActionLink: href,
            })
          }
          mode={ButtonMode.Light}
          text={buttonLabel}
          {...props}
        />
      )}
    </div>
  );
};
